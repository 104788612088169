import BTC from "image/homepage/Crypto/btc.png";
import BNB from "image/homepage/Crypto/bnb.png";
import BCH from "image/homepage/Crypto/bch.png";
import ETH from "image/homepage/Crypto/eth.png";
import LTC from "image/homepage/Crypto/ltc.png";
import USDT from "image/homepage/Crypto/usdt.png";
import XDC from "image/homepage/Crypto/xdc.png";
import XLAB from "image/homepage/Crypto/xlab.png";
import XLD from "image/homepage/Crypto/xld.png";
import SBI from "image/homepage/Crypto/shibainu.png";
import MTC from "image/homepage/Crypto/matic.png";
import BUSD from "image/homepage/Crypto/busd.png";
import BABYDODGE from "image/homepage/Crypto/babydoge.png";

export const cryptos = [
  {
    name: "BTC",
    img: BTC,
    link: "https://www.bitcoin.com/quality=auto",
    headingTitle: "Bitcoin",
    bgColor: "bg-orange-50 dark:bg-orange-50/20",
  },
  {
    name: "ETH",
    img: ETH,
    link: "https://ethereum.org/quality=auto",
    headingTitle: "Ethereum",
    bgColor: "bg-black/5 dark:bg-slate-200/10",
  },
  {
    name: "XcelPay",
    img: XLD,
    link: "https://xcelpay.io/",
    headingTitle: "XcelPay",
    bgColor: "bg-[#1e1f4b]/5 dark:bg-[#1e1f4b]/90",
  },
  {
    name: "XLAB",
    img: XLAB,
    link: "https://xceltoken.com/quality=auto",
    headingTitle: "Xcel Token",
    bgColor: "bg-orange-200/20  dark:bg-orange-200/30",
  },
  {
    name: "USDT",
    img: USDT,
    link: "https://tether.to/quality=auto",
    headingTitle: "Tether",
    bgColor: "bg-green-50 dark:bg-green-50/20",
  },
  {
    name: "BNB",
    img: BNB,
    link: "https://bnbwall.com/#/quality=auto",
    headingTitle: "Binance",
    bgColor: "bg-yellow-50 dark:bg-yellow-50/20",
  },
  {
    name: "XDC",
    img: XDC,
    link: "https://www.xdc.org/quality=auto",
    headingTitle: "XinFin ",
    bgColor: "bg-blue-500/10",
  },
  {
    name: "BCH",
    img: BCH,
    link: "https://bitcoincash.org/quality=auto",
    headingTitle: "Bitcoin Cash",
    bgColor: "bg-green-300/10 bg-green-300/30",
  },
  {
    name: "LTC",
    img: LTC,
    link: "https://litecoin.com/en/quality=auto",
    headingTitle: "Litecoin",
    bgColor: "bg-blue-300/20",
  },

  {
    name: "BCH",
    img: SBI,
    link: "https://shibatoken.com/",
    headingTitle: "Shiba Inu",
    bgColor: "bg-red-500/5 dark:bg-red-500/30",
  },
  {
    name: "MTC",
    img: MTC,
    link: "https://polygon.technology/",
    headingTitle: "Polygon",
    bgColor: "bg-purple-500/5 dark:bg-purple-500/30",
  },
  {
    name: "BABYDODGE",
    img: BABYDODGE,
    link: "https://babydoge.com/",
    headingTitle: "Babydodge",
    bgColor: "bg-orange-500/5 dark:bg-orange-500/30",
  },
];
