import { cryptos } from "comp/cryptoConfig";
import Link from "next/link";
import Image from "next/image";
import { Icon } from "@iconify/react";
import AdsImage from "image/homepage/ads.webp";
import React, { useEffect, useState } from "react";

const CryptoPage = () => {
  return (
    <>
      <section
        className="relative bg-white dark:bg-dark-bg-primary"
        id="cryptoSection"
      >
        <div className="container mx-auto bg-base-200">
          <div className="crypto-content ">
            <div className="mb-8 animate-heading" id="crypto_heading">
              <h3
                className="popular_header  font-bold text-xl sm:text-3xl text-[#1a1a1a] dark:text-dark-text-primary m-0  "
                style={{ "--animation-order": 1 }}
              >
                Travel with crypto
              </h3>
              <p className="font-normal dark:text-dark-text-secondary ">
                Easily Pay for your bookings using your favourite
                cryptocurrencies
              </p>
            </div>
            <ul
              id="crypto_ul"
              className={`p-0 mb-10 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 justify-center items-center place-content-center  try-animation`}
            >
              {cryptos.map((crypto, key) => (
                <li
                  key={`${key + 1 + ""}`}
                  className={`pay_crypo_img `}
                  style={{ "--animation-order": key + 1 }}
                >
                  <a
                    href={crypto.link}
                    target="_blank"
                    key={`${crypto.name}-${key}`}
                    className={` ${crypto.bgColor}   !h-[60px] !w-[60px] sm:!h-[90px] mx-auto sm:!w-[90px] p-0 flex  items-center rounded-full justify-center  `}
                    title={crypto.headingTitle}
                  >
                    <Image
                      fill
                      src={crypto.img}
                      alt="crypto_img"
                      unoptimized
                      className="crypto_img sm:p-0 !static  object-contain  !w-auto !h-[40px] sm:!h-[56px]  mix-blend-normal mx-auto   "
                    />
                    {/* <span className="text-base font-medium tracking-wide">
                      {crypto.name}
                    </span> */}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {/* <div className="flex justify-center ">
            <button className="flex items-center gap-3 px-3 py-2 text-sm font-medium border-2 rounded sm:text-base text-main-100 border-main-100 sm:py-4 sm:px-6 hover:bg-main-100 hover:text-white">
              <Icon className="w-6 h-6" icon="akar-icons:plus" />
              List your token
            </button>
          </div> */}

          {/* <div className="">
            <div className="flex justify-center pt-16 ">
              <Image className="w-full rounded-md" src={AdsImage} alt="image" />
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default CryptoPage;
